import http from "./http-client";

class AuthService {
  login(payload) {
    return http.post("/Auth/login", payload);
  }
  logout() {
    return http.post("/Token/Revoke");
  }
  // revokeAll(payload) {
  //   return http.post("/revoke-all", payload);
  // }
  // resister(payload) {
  //   return http.post("/resister", payload);
  // }
  // resisterAdmin(payload) {
  //   return http.post("/resister-admin", payload);
  // }
}

export default new AuthService();

import store from "../../store";
import SecureStorage from "secure-web-storage";

const redirectPageName = () => {
  const isLoggedIn = store.getters["auth/loggedIn"];
  // const role = store.state.auth.userState.roleName;
  if (isLoggedIn) {
    return "County";
  }
  // if (isLoggedIn && role === "Agent") {
  //   return "Events";
  // }
  // if (isLoggedIn && role === "Coordinator") {
  //   return "Events";
  // }
  if (!isLoggedIn) {
    return "Login";
  }

  return "Login";
};
const menuList = () => {
  return [
    {
      menuName: "County Zip",
      icon: "mdi-list-box",
      link: "/city-county-list",
      // subLink: "/events/addupdate",
      roles: ["Admin", "IT", "Lead"],
    },
    {
      menuName: "User Management",
      icon: "mdi-account",
      link: "/users",
      roles: ["Admin", "IT"],
    },
  ];
};
const toMMDDYYYY = (d) => {
  const date = d.split(" ")[0].split("-");
  return `${date[0]}/${date[1]}/${date[2]}`;
};
// const toBase64 = (file) =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = (error) => reject(error);
//   });

const secureStorage = () => {
  var CryptoJS = require("crypto-js");

  var SECRET_KEY = "SRLIFE-PWA";
  return new SecureStorage(localStorage, {
    hash: function hash(key) {
      // key = CryptoJS.SHA256(key, SECRET_KEY);

      return key.toString();
    },
    encrypt: function encrypt(data) {
      data = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY);

      data = data.toString();

      return data;
    },
    decrypt: function decrypt(data) {
      data = CryptoJS.AES.decrypt(data, SECRET_KEY);

      data = data.toString(CryptoJS.enc.Utf8);

      return JSON.parse(data);
    },
  });
};
const removeToken = () => {
  // secureStorage().removeItem("ConnectionKey");
  secureStorage().removeItem("token");
  secureStorage().removeItem("userId");
  secureStorage().removeItem("userName");
  secureStorage().removeItem("roleId");
  secureStorage().removeItem("roleName");
  secureStorage().removeItem("email");
  secureStorage().removeItem("address");
  secureStorage().removeItem("phone");
  secureStorage().removeItem("active");
  secureStorage().removeItem("refreshToken");
  secureStorage().removeItem("expiryTime");
  secureStorage().removeItem("notificationCount");
  secureStorage().removeItem("actionItems");
  secureStorage().removeItem("User");
  secureStorage().removeItem("setMessage");
};
export {
  redirectPageName,
  menuList,
  toMMDDYYYY,
  // toBase64,
  secureStorage,
  removeToken,
};

import AuthService from "../../services/AuthService";
import { secureStorage } from "../../utils/helpers";

export default {
  namespaced: true,
  state: {
    userState: {
      token: "",
      refreshToken: "",
      userName: "",
      roleName: "",
      loggedIn: "",
      user: {},
      actionItems: [],
    },
  },
  actions: {
    authRequest: ({ commit }, user) => {
      return new Promise((resolve, reject) => {
        AuthService.login(user)
          .then((resp) => {
            commit("authSuccess", resp.data);
            resolve(resp);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    changeUserState: ({ commit }) => {
      try {
        const userState = {
          token: secureStorage().getItem("token") || "",
          refreshToken: secureStorage().getItem("refreshToken") || "",
          userName: secureStorage().getItem("userName") || "",
          roleName: secureStorage().getItem("roleName") || "",
          loggedIn: [undefined, null, ""].includes(
            secureStorage().getItem("token")
          )
            ? false
            : true,
          user: secureStorage().getItem("User") || {},
          actionItems: secureStorage().getItem("actionItems") || "",
        };
        if (userState) {
          commit("updateUserDetail", userState);
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
  getters: {
    auth(state) {
      return state.userState;
    },
    loggedIn(state) {
      return !!state.userState.token;
    },
    roleName(state) {
      return state.userState.roleName;
    },
    actionItems(state) {
      return state.userState.actionItems;
    },
  },
  mutations: {
    authSuccess: (state, res) => {
      if (res.isSuccess) {
        const { data } = res;
        state.userState.token = data.token;
        state.userState.userName = data.userName;
        state.userState.roleName = data.roleName;
        state.userState.refreshToken = data.refreshToken;
        state.userState.user = data;
        for (const key in data) {
          if (Object.hasOwnProperty.call(data, key)) {
            secureStorage().setItem(key, data[key]);
          }
        }
        secureStorage().setItem("User", data);
      }
    },
    updateUserDetail(state, res) {
      state.userState = res;
    },
    resetAuth: (state) => {
      state.userState.token = "";
      state.userState.userName = "";
      state.userState.roleName = "";
      state.userState.user = {};
      state.userState.actionItems = [];
      state.userState.refreshToken = "";
      state.userState.loggedIn = false;
      secureStorage().clear();
    },
  },
};

<template>
  <v-app app>
    <notifications-bar></notifications-bar>

    <v-main class="pt-0">
      <!-- <component :is="layout"> -->
      <router-view :key="$route.fullPath" />
      <!-- </component> -->
    </v-main>
  </v-app>
</template>

<script>
import NotificationsBar from "@/components/NotificationsBar.vue";

export default {
  name: "App",
  components: {
    NotificationsBar,
  },
  // updated() {
  //   if (this.$route.path !== "/404") {
  //     setTimeout(() => {
  //       const sectionContainer = document.querySelector(".section-container");
  //       const adminContainet = document.querySelector(".admin-container");
  //       const footerHeight =
  //         document.getElementsByTagName("footer")[0].clientHeight;
  //       if (window.innerWidth <= 640) {
  //         if (sectionContainer !== null) {
  //           sectionContainer.style.paddingBottom = `${footerHeight + 20}px`;
  //         }
  //         if (adminContainet !== null) {
  //           adminContainet.style.paddingBottom = `${footerHeight + 20}px`;
  //         }
  //       }
  //     }, 1000);
  //   }
  // },
  computed: {
    layout() {
      return `${this.$route.meta.layout || "DefaultLayout"}`;
    },
  },
  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.showUpdateUI = true;
      });
    }
  },
  async accept() {
    this.showUpdateUI = false;
    await this.$workbox.messageSW({ type: "SKIP_WAITING" });
  },
};
</script>

import http from "./http-client";

class UserService {
  addUser(payload) {
    return http.post("/User/AddUser", payload);
  }
  getUserDetail(payload) {
    return http.get("/User/Roles", payload);
  }
  updateUser(payload) {
    return http.post("/User/UpdateUser", payload);
  }
  userList(payload) {
    if (!payload) {
      return http.get(`/User/UserList`);
    } else {
      return http.get(`/User/UserList?isActive=${payload}`);
    }
  }
  getRolesList(payload) {
    return http.get("/User/RoleList", payload);
  }
  // updateUserRole(payload) {
  //   return http.put("/User/UpdateProfile", payload);
  // }
  changePassword(payload) {
    return http.post("/User/ChangePassword", payload);
  }
  InActiveUser(payload) {
    return http.post("/User/InactiveUser", payload);
  }
}

export default new UserService();

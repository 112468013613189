var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('validation-observer',{ref:"modalObserver",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('v-form',{ref:"modalForm",staticClass:"newClass",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.changePassword)}}},[_c('v-card-title',{staticClass:"card-title-dialog"},[_c('span',{staticClass:"text-h6"},[_vm._v("Change Password")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Old Password","rules":{
                    required: true,
                  },"vid":"confirm","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"form-control",attrs:{"label":"Old Password","placeholder":"Old Password","required":"","outlined":"","append-icon":_vm.showOldPassword ? 'mdi-eye' : 'mdi-eye-off',"hide-details":!errors.length,"error-messages":errors,"prepend-icon":"mdi-lock-check","type":_vm.showOldPassword ? 'text' : 'password',"disabled":_vm.loading},on:{"click:append":function($event){_vm.showOldPassword = !_vm.showOldPassword}},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Password","rules":{
                    required: true,
                    min: 8,
                    regex:
                      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/,
                  },"vid":"confirm","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"form-control",attrs:{"label":"Password","title":"Password should be of atleast 8 characters and requires atleast one : uppercase letter, lowercase letter, number, special character.","placeholder":"Password","required":"","outlined":"","append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"hide-details":!errors.length,"error-messages":errors,"prepend-icon":"mdi-lock-check","type":_vm.showPassword ? 'text' : 'password',"disabled":_vm.loading},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Confirm Password","rules":{
                    required: true,
                    confirmed: _vm.password,
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"form-control",attrs:{"hint":"Password should be of atleast 8 characters and requires atleast one : uppercase letter, lowercase letter, number, special character.","label":"Confirm Password","placeholder":"Confirm Password","required":"","outlined":"","append-icon":_vm.showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off',"hide-details":!errors.length,"error-messages":errors,"prepend-icon":"mdi-lock-check","type":_vm.showConfirmPassword ? 'text' : 'password',"disabled":_vm.loading},on:{"click:append":function($event){_vm.showConfirmPassword = !_vm.showConfirmPassword}},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})]}}],null,true)})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.resetForm(), _vm.$emit('disableDialog', false)}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"add-btn","type":"submit","text":"","disabled":_vm.loading,"loading":_vm.loading}},[_vm._v(" Update ")])],1)],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <validation-observer ref="modalObserver" v-slot="{ handleSubmit }">
        <v-form
          class="newClass"
          @submit.prevent="handleSubmit(changePassword)"
          ref="modalForm"
        >
          <v-card-title class="card-title-dialog">
            <span class="text-h6">Change Password</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Old Password"
                    :rules="{
                      required: true,
                    }"
                    vid="confirm"
                    mode="lazy"
                  >
                    <v-text-field
                      label="Old Password"
                      v-model="oldPassword"
                      class="form-control"
                      placeholder="Old Password"
                      required
                      outlined
                      :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      prepend-icon="mdi-lock-check"
                      :type="showOldPassword ? 'text' : 'password'"
                      @click:append="showOldPassword = !showOldPassword"
                      :disabled="loading"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Password"
                    :rules="{
                      required: true,
                      min: 8,
                      regex:
                        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/,
                    }"
                    vid="confirm"
                    mode="lazy"
                  >
                    <v-text-field
                      label="Password"
                      v-model="password"
                      title="Password should be of atleast 8 characters and requires atleast one : uppercase letter, lowercase letter, number, special character."
                      class="form-control"
                      placeholder="Password"
                      required
                      outlined
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      prepend-icon="mdi-lock-check"
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                      :disabled="loading"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Confirm Password"
                    :rules="{
                      required: true,
                      confirmed: password,
                    }"
                  >
                    <v-text-field
                      hint="Password should be of atleast 8 characters and requires atleast one : uppercase letter, lowercase letter, number, special character."
                      label="Confirm Password"
                      v-model="confirmPassword"
                      class="form-control"
                      placeholder="Confirm Password"
                      required
                      outlined
                      :append-icon="
                        showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                      "
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      prepend-icon="mdi-lock-check"
                      :type="showConfirmPassword ? 'text' : 'password'"
                      @click:append="showConfirmPassword = !showConfirmPassword"
                      :disabled="loading"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="resetForm(), $emit('disableDialog', false)"
            >
              Close
            </v-btn>

            <v-btn
              color="add-btn"
              type="submit"
              text
              :disabled="loading"
              :loading="loading"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-form>
      </validation-observer>
    </v-card>
    <!-- </template> -->
  </v-dialog>
</template>

<script>
import UserService from "../../services/UserService.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { secureStorage } from "../../utils/helpers/index.js";
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    dataReceived: {
      type: Object,
    },
  },

  data: () => ({
    password: "",
    oldPassword: "",
    loading: false,
    showPassword: false,
    showConfirmPassword: false,
    showOldPassword: false,
    confirmPassword: "",
  }),
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    async changePassword() {
      try {
        const response = await UserService.changePassword({
          userId: secureStorage().getItem("userId"),
          oldPassword: this.oldPassword,
          newPassword: this.password,
        });
        if (response.data.isSuccess) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "success",
            },
            { root: true }
          );
          this.resetForm();

          this.$emit("disableDialog", false);
        } else {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.data,
              type: "error",
            },
            { root: true }
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    resetForm() {
      this.$refs.modalObserver.reset();
      // this.$refs.modalForm.reset();

      this.password = "";
      this.confirmPassword = "";
      this.oldPassword = "";
    },
  },
};
</script>

<template>
  <!-- <v-card> </v-card> -->
  <div class="footer">
    <p>Senior Life Insurance Company Ⓒ Copyright 2023</p>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>
